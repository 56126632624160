import styled from 'react-emotion'

import t from 'src/theme'
import AssetTextureChalk from 'src/assets/texture-chalk.png'

export const Container = styled.div`
  background-color: #222;
  background-image: url(${AssetTextureChalk});
  background-repeat: repeat;
  background-size: 100% auto;
  color: ${t.c.white};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  line-height: ${t.lh.wide};
  padding: ${t.s(2)};
  text-align: center;

  ${t.mq.m} {
    padding: ${t.s(5, 2)};
  }

  h1 {
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(2)};
    margin-bottom: ${t.s(1)};

    ${t.mq.m} {
      font-size: ${t.f(3)};
    }
  }
`
