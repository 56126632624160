import React from 'react'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import { Container } from './ChalkboardBox.styled'

export const ChalkboardBox = ({ children, ...props }) => (
  <Container {...props}>
    <LargeContentContainer>{children}</LargeContentContainer>
  </Container>
)
