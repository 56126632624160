import styled from 'react-emotion'
import { Field } from 'formik'

import t from 'src/theme'

export const Container = styled.div``

export const FieldRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${t.s(0)};

  ${t.mq.m} {
    flex-direction: row;
  }
`

export const FieldLabel = styled.label`
  margin-right: ${t.s(1)};
`

export const StyledField = styled(Field)`
  appearance: none;
  background-color: ${t.c.white};
  border-radius: ${t.r(-8)};
  border: 1px solid ${t.c.gray};
  color: ${t.c.darkGray};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  height: ${p => (p.component === 'textarea' ? t.s(7) : 'auto')};
  margin-bottom: ${t.s(0)};
  padding: ${t.s(-1, 0)};
  text-align: ${p => (p.component === 'textarea' ? 'left' : 'center')};
  width: 100%;

  ${t.mq.m} {
    flex-basis: 50%;
    margin-bottom: 0;
    margin-right: ${t.s(1)};
    text-align: left;
    width: auto;
  }

  &:first-child:last-child {
    ${t.mq.m} {
      flex-basis: 100%;
    }
  }

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  &::placeholder {
    color: ${t.c.gray};
    font-style: italic;
  }
`

export const PlaceholderInput = StyledField.withComponent('input')

export const SubmitButton = styled.input`
  appearance: none;
  background-color: ${t.c.lightGreen};
  border-radius: 3px;
  border: 0;
  color: ${t.c.white};
  cursor: pointer;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-top: ${t.s(1)};
  padding: ${t.s(-1)} ${t.s(4)};
  text-align: center;
  text-transform: uppercase;
  transition-duration: ${t.t};
  transition-property: background-color, color;

  &:disabled {
    background-color: ${t.c.gray};
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: ${t.c.green};
    color: ${t.c.white};
  }
`

export const ThankYouContainer = styled.div`
  text-align: center;
`

export const Error = styled.p`
  color: ${t.c.darkPink};
  font-weight: bold;
`
