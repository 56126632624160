import styled from 'react-emotion'

import t from 'src/theme'
import { Link } from 'src/components/Shared/Link/Link'
import { ReactComponent as AssetIconChevronLeftSVG } from 'src/assets/icon-chevron-left.svg'

export const Container = styled(Link)`
  align-items: center;
  color: ${t.c.gray};
  display: inline-flex;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: ${t.lh.solid};
  margin-top: ${t.s(0)};
  padding: ${t.s(0)};
  text-decoration: none;
  text-transform: uppercase;
`

export const Icon = styled(AssetIconChevronLeftSVG)`
  display: block;
  margin-right: ${t.s(-3)};
  width: ${t.s(-2)};
`

export const Content = styled.div``
