import React from 'react'

import { TapeHeading } from 'src/components/Shared/TapeHeading/TapeHeading'
import { Container, Heading, Content } from './Section.styled'

export const Section = ({
  heading,
  renderHeading,
  textAlign = 'center',
  children,
  ...props
}) => (
  <Container>
    <Heading>
      {renderHeading
        ? renderHeading()
        : heading && <TapeHeading>{heading}</TapeHeading>}
    </Heading>
    <Content textAlign={textAlign}>{children}</Content>
  </Container>
)
