import styled from 'react-emotion'
import t from 'src/theme'

export const Content = styled.div`
  background-color: ${t.c.lightCream};
  color: ${t.c.darkGray};
  font-family: ${t.ff.monoBody};
  padding: ${t.s(3, 2)};

  ${t.mq.m} {
    padding: ${t.s(5, 2)};
  }
`

export const Heading = styled.div`
  color: ${t.c.lightGreen};
  font-family: ${t.ff.sansHeadline};
  font-size: ${t.f(3)};
  font-weight: bold;
  margin-bottom: ${t.s(-4)};
  text-align: center;
  text-transform: uppercase;
`

export const SecondaryHeading = styled(Heading)`
  color: ${t.c.green};
  font-size: ${t.f(2)};
  margin-bottom: ${t.s(1)};
  text-transform: none;
`

export const Details = styled.div`
  color: ${t.c.gray};
  font-size: ${t.f(-0.5)};
  letter-spacing: 0.1em;
  margin-bottom: ${t.s(2)};
  text-align: center;
  text-transform: uppercase;
`

export const Sections = styled.div`
  margin-bottom: ${t.s(2)};
`

export const Section = styled.div`
  margin-bottom: ${t.s(3)};
`

export const SectionHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${t.s(0)};
`

export const SectionContent = styled.div`
  font-size: ${t.f(-0.5)};
  line-height: ${t.lh.wide};
  text-align: ${p => p.textAlign || 'center'};

  ul {
    padding-left: ${t.s(0)};
  }
`
