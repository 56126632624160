import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  margin-bottom: ${t.s(3)};
  display: flex;
  flex-direction: column;

  ${t.mq.m} {
    align-items: flex-start;
    flex-direction: row;
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const Heading = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${t.s(0)};

  ${t.mq.m} {
    flex-basis: 25%;
    margin-right: ${t.s(2)};
  }
`

export const Content = styled.div`
  font-size: ${t.f(-0.5)};
  line-height: ${t.lh.wide};
  text-align: ${p => p.textAlign || 'center'};

  ${t.mq.m} {
    flex-basis: 75%;
    padding-top: ${t.s(-4)};
    text-align: left;
  }

  ul {
    margin-top: 0;
    padding-left: ${t.s(0)};
  }
`
