import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get } from 'lodash'

import { BackButton } from 'src/components/Shared/BackButton/BackButton'
import { ChalkboardBox } from 'src/components/Shared/ChalkboardBox/ChalkboardBox'
import { Form } from 'src/components/EmploymentPosition/Form/Form'
import { LargeContentContainer } from 'src/components/Layout/Structure'
import { Layout } from 'src/components/Layout/Layout'
import { Section } from 'src/components/EmploymentPosition/Section/Section'
import {
  Content,
  Heading,
  SecondaryHeading,
  Details,
  Sections,
} from './employmentPosition.styled'

const EmploymentPositionTemplate = ({ data }) => {
  const position = get(data, 'prismicEmploymentPosition')
  const locationName = get(
    position,
    'data.location.document.data.location_name'
  )

  return (
    <Layout activePageName="employment">
      <Helmet
        title={`${get(
          data,
          'prismicEmploymentPosition.data.display_title.text'
        )} at ${locationName}`}
      />

      <ChalkboardBox>
        <div
          dangerouslySetInnerHTML={{
            __html: get(data, 'prismicEmploymentPage.data.intro.html'),
          }}
        />
      </ChalkboardBox>
      <Content>
        <LargeContentContainer>
          <Heading>{get(position, 'data.display_title.text')}</Heading>
          <Details>
            {locationName} / {get(position, 'data.type')}
          </Details>
          <Sections>
            <Section heading="Description">
              <div
                dangerouslySetInnerHTML={{
                  __html: get(position, 'data.full_description.html'),
                }}
              />
            </Section>
            <Section heading="Requirements" textAlign="left">
              <div
                dangerouslySetInnerHTML={{
                  __html: get(position, 'data.requirements.html'),
                }}
              />
            </Section>
            <Section
              renderHeading={() => (
                <SecondaryHeading>Apply Now</SecondaryHeading>
              )}
            >
              <Form
                locationName={locationName}
                title={get(position, 'data.display_title.text')}
                type={get(position, 'data.type')}
              />
              <BackButton to="/employment/">Back to All</BackButton>
            </Section>
          </Sections>
        </LargeContentContainer>
      </Content>
    </Layout>
  )
}

export default EmploymentPositionTemplate

export const query = graphql`
  query EmploymentPositionTemplate($id: String!) {
    prismicEmploymentPosition(id: { eq: $id }) {
      uid
      data {
        display_title {
          text
        }
        location {
          document {
            ... on PrismicLocation {
              uid
              data {
                location_name
              }
            }
          }
        }
        type
        full_description {
          html
        }
        requirements {
          html
        }
      }
    }
    prismicEmploymentPage {
      id
      data {
        intro {
          html
        }
      }
    }
  }
`
