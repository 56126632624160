import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Formik, Form as FormikForm, Field } from 'formik'
import { object as yupObject, mixed as yupMixed } from 'yup'
import { get, isEmpty, head, values } from 'lodash'

import { postNetlifyForm } from 'src/helpers'
import {
  Container,
  FieldRow,
  FieldLabel,
  StyledField,
  PlaceholderInput,
  SubmitButton,
  ThankYouContainer,
  Error,
} from './Form.styled'

const NETLIFY_FORM_NAME = 'Employment Application'

const onSubmit = async (values, { setSubmitting, setStatus }) => {
  const result = await postNetlifyForm(NETLIFY_FORM_NAME, values)
  if (result) setStatus({ submitted: true })
  setSubmitting(false)
}

const render = ({ locationName, title, type, ...props }) => queryData => (
  <Container>
    <Formik
      initialValues={{
        subject: `[${locationName}] ${NETLIFY_FORM_NAME} form submission`,
        locationName,
        position: title,
        type,
        firstName: '',
        lastName: '',
        emailAddress: '',
        about: '',
        resume: null,
      }}
      onSubmit={onSubmit}
      validationSchema={yupObject().shape({
        resume: yupMixed().test(
          'fileSize',
          'File size is too large. Please select a file under 2 MB.',
          value => get(value, 'size', 0) <= 2e6
        ),
      })}
    >
      {({ setFieldValue, status, errors, isSubmitting }) =>
        get(status, 'submitted') ? (
          <ThankYouContainer
            dangerouslySetInnerHTML={{
              __html: get(
                queryData,
                'prismicEmploymentPage.data.form_success_text.html'
              ),
            }}
          />
        ) : (
          <FormikForm
            name={NETLIFY_FORM_NAME}
            method="post"
            data-netlify={true}
            data-netlify-honeypot="bot-field"
          >
            <Field type="hidden" name="subject" />
            <input type="hidden" name="form-name" value={NETLIFY_FORM_NAME} />
            <input type="hidden" name="locationName" value={locationName} />
            <input type="hidden" name="position" value={title} />
            <input type="hidden" name="type" value={type} />
            <input type="file" name="resume" hidden />
            <FieldRow>
              <StyledField name="firstName" placeholder="First Name" required />
              <StyledField name="lastName" placeholder="Last Name" required />
            </FieldRow>
            <FieldRow>
              <StyledField
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                required
              />
            </FieldRow>
            <FieldRow>
              <StyledField
                component="textarea"
                name="about"
                placeholder="Tell us about yourself: Why do you want to work at Kalapawai?"
                required
              />
            </FieldRow>
            <FieldRow>
              <FieldLabel for="resume">Resume</FieldLabel>
              <PlaceholderInput
                id="resume"
                type="file"
                onChange={event => {
                  setFieldValue('resume', event.target.files[0])
                }}
              />
            </FieldRow>
            {!isEmpty(errors) && <Error>{head(values(errors))}</Error>}
            <SubmitButton
              type="submit"
              value={isSubmitting ? 'Submitting…' : 'Apply'}
              disabled={!isEmpty(errors) || isSubmitting}
            />
          </FormikForm>
        )
      }
    </Formik>
  </Container>
)

export const Form = props => (
  <StaticQuery
    query={graphql`
      query {
        prismicEmploymentPage {
          data {
            form_success_text {
              html
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
