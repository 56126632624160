import React from 'react'

import { Container, Icon, Content } from './BackButton.styled'

export const BackButton = ({ children, ...props }) => (
  <Container {...props}>
    <Icon />
    <Content>{children}</Content>
  </Container>
)
